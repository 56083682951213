import React from 'react';
import Hoc from '../../utils/Hoc';
import Footer from '../footer/footer.comp';
import Navbar from '../navbar/navbar.comp';
import { PageWrapper } from './layout.styled';

export default function Layout({ children }) {
  return (
    <Hoc>
      <Navbar />
      <PageWrapper>
        {children}

        <Footer />
      </PageWrapper>
    </Hoc>
  );
}
