const businessData = [
  {
    id: 1,
    businessName: 'Wilkes Wings',
    productCategory: 'Food & Beverage',
    companyWebsite: 'https://wilkeswings.com',
    companyDescription:
      'Alfa Products manufacture and distribute premium Buffalo wing sauces for the retail, food service, and e-commerce channels.  Our award winning sauces include Sweet Heat, Lemon Butter Pepper, and Whiskey flavors.  These sauces are unique not only in flavor but in application too - customers use them on an array of dishes other than chicken wings.  So whether you like it sweet, spicy or somewhere in between, Wilkes Wings has the perfect sauce for you!',
    image:
      'https://api.typeform.com/responses/files/1ebc98ca8247677ea422576c275ca64dc14efb747d31fefd6d70a80dbdaa0d6d/IMG_0896.jpg',
  },
  {
    id: 2,
    businessName: 'Bazile Sauce',
    productCategory: 'Food & Beverage',
    companyWebsite: 'https://www.bazilesauce.com/',
    companyDescription:
      'Bazile Sauce was created for the at-home cook who is on-the-go and wants delicious & flavorful food quickly without compromising on ingredients and the planet.',
    image:
      'https://api.typeform.com/responses/files/166d496dde5ecd74ca8c779adbf2e4186363cf0567773826a337efff8435ebff/Bazile_Sauce_Owner_Picture_with_Bottles_at_CPF.JPG',
  },
  {
    id: 3,
    businessName: 'Black Cap Ginger Brew',
    productCategory: 'Food & Beverage',
    companyWebsite: 'https://blackcapginger.com',
    companyDescription:
      'Black Cap Ginger Brew is a healthy and all-natural noncarbonated ginger drink that will boosts the immune system, relieves joint pain, reduces inflammation, and more. There are four delicious flavors jam-packed with ginger and other healthy ingredients.',
    image:
      'https://api.typeform.com/responses/files/fbfddcb42542ac0e29d81303c73792a91b5c0a40a03aee20fbcc2d088135465f/received_396117795011562.jpeg',
  },
  {
    id: 4,
    businessName: 'Bold & Gritty',
    productCategory: 'Food & Beverage',
    companyWebsite: 'https://www.boldandgritty.com',
    companyDescription: 'Coffee focused life-style brand.',
    image:
      'https://api.typeform.com/responses/files/f33f2f19018f7dba7dd89f4b3441f63334b1bb2e98f4d39a811243177897ac0a/2L0A4222.JPG',
  },
  {
    id: 5,
    businessName: "Camella's Kitchen",
    productCategory: 'Food & Beverage',
    companyWebsite: 'https://www.camellaskitchen.com/',
    companyDescription:
      'Founded by a mother and daughter duo from Trinidad and Tobago, Camella’s Kitchen is helping to elevate meals at home and on the go with our bold Caribbean flavors – we ship. Rooted in tradition, all of our flavors are handcrafted in small batches. We make hot sauces, seasonings, cakes and more. We believe in high quality ingredients, and try to source from local farms as much as we can. Furthermore, it is our hope that through Camella’s Kitchen, people learn how to use the everyday Caribbean flavors we grew up with to make tastier, and healthier meals at home. To help with this goal, we’ve also created recipes that are easily accessible on our website. We’re here to help people live their best lives and #BeCamellaSaucy!',
    image:
      'https://api.typeform.com/responses/files/96a2c9ffa74b2837cffc1dc7b84e7a36397783eab170e8ccfe9a385b02f678d9/Small_Caribbean_Fruit_Cake___Camella_s_Kitchen.jpg',
  },
  {
    id: 6,
    businessName: 'COOL Creative',
    productCategory: 'Apparel',
    companyWebsite: 'http://shopcoolcreative.com',
    companyDescription:
      'COOL® Creative is a fashion line that offers a full line of clothing and accessories with a mission to outfit the movement for social justice and celebrate past and present change-agents; who have shaped culture. Our clothing is designed to energize, educate and embrace people of color and its allies.',
    image:
      'https://api.typeform.com/responses/files/9cf108b2fd6de2f23873df9ba2de0598cc5bcd06459e3988c9e05a0ce97eb623/064FA3F3_D157_473B_8CB2_6AE53DD7173B.jpeg',
  },
  {
    id: 7,
    businessName: 'Eve Milan New York',
    productCategory: 'Beauty',
    companyWebsite: 'https://www.EveMilanNy.com',
    companyDescription:
      'EVE MILAN™| New York is a luxury skin care company founded by Eden Gilliam an Esthetician with almost 10 years experience in the skin care industry. After working hands on with thousands of people she gained first hand insight on the day to day struggles people have with their skin. Most clients just masked there issues and insecurities with makeup and felt hopeless in achieving their skincare goals due to lack of understanding and knowledge of quality products. From her interactions with clients she coined the phrase:“Wear make up because you want to not because you have to”™',
    image:
      'https://api.typeform.com/responses/files/62bc8ae6d04152b52a0467a88e12953fe9650674901351eacd6567703316fb6a/DF9E2B68_1B2D_425E_B9A6_56784C853AD3.jpeg',
  },
  {
    id: 8,
    businessName: 'Fit4Dance',
    productCategory: 'Fitness',
    companyWebsite: 'https://fit4dancenyc.com',
    companyDescription:
      'Fit4Dance is dedicated to bringing quality and affordable dance and fitness instruction to communities that need it the most!  We offer virtual classes where folks can have fun, learn to dance, improve their fitness and health, meet new people, build community, and explore other cultures in a safe, warm, and welcoming environment.',
    image:
      'https://api.typeform.com/responses/files/da6f377b7e77a959a093e22aeb31396d88222dff74b9a1d5d42fd0c86dd18586/151114_Laci_406_Edit.jpg',
  },
  {
    id: 9,
    businessName: 'Fulaba',
    productCategory: 'Jewelry',
    companyWebsite: 'https://www.fulaba.com',
    companyDescription:
      'Fulaba provides statement jewelry connected to and inspired by African High Culture. Fulaba has been featured in Essence, Allure Magazine, Refinery29 and more. Our jewelry has been worn by celebrities and influencers, including Alicia Keys. Our collections include Fulani jewelry made in Guinea, Wolof jewelry made in Senegal and signature jewelry made in the U.S.',
    image: require('../assets/img/businesses/fulaba.jpg').default,
  },
  {
    id: 10,
    businessName: 'Hippie Adjacent',
    productCategory: 'Accesories',
    companyWebsite: 'https://www.hippieadjacent.com/',
    companyDescription:
      'Hippie Adjacent supports individuals of all gender expressions in presenting their most authentic self to the world through distinctive accessories at the intersection of fashion and aromatherapy',
    image:
      'https://api.typeform.com/responses/files/51caa347410860936236eea856de573b1708f7ec8d2618d94fe6ee69604311ba/30.1.JPEG',
  },

  {
    id: 11,
    businessName: 'Interior Motives by Will Smith LLC',
    productCategory: 'Homegoods',
    companyWebsite: 'https://interiormotivesfurniture.com',
    companyDescription:
      'A Modern Furniture Store and Interior Design Firm.  We offer Furniture, Home Decor, Wallpaper, Drapery, Lighting, Rugs, Art, Fabrics, Wall and Ceiling Tiles and Full Interior Design services for Commercial and Residential customers.',
    image:
      'https://api.typeform.com/responses/files/dd0666642d1c3a0a847be8a94edf29f0eedcd85ff1dbdaeb2974527d47a67144/Screen_Shot_2021_01_22_at_7.22.02_PM.png',
  },

  {
    id: 12,
    businessName: 'IVXV Apparel',
    productCategory: 'Apparel',
    companyWebsite: 'https://ivxvapparel.com',
    companyDescription:
      'IVXV Apparel is a thought-provoking & aspirational streetwear brand based in California. We currently sell hats & t-shirts with plans to add hoodies & sweatpants by the end of Q1 2021.',
    image:
      'https://api.typeform.com/responses/files/7091fad902aef4d07132db84e8aa7f29a082b39f78e9a71f445400894dcc6f15/IVXV_Logo_T_Black_MetallicSilver.jpg',
  },

  {
    id: 13,
    businessName: 'Jurni',
    productCategory: 'Books',
    companyWebsite: 'https://thejurni.co/',
    companyDescription:
      'Jurni highlights the importance of healthy communication and how to deal with grief and loss through the eyes of a young black girl.',
    image:
      'https://api.typeform.com/responses/files/cc70c9a0821d0da6cb88f6327c0966a54b0e7bfc964205c2330fa8a78f01cc8d/jurni.jpg',
  },

  {
    id: 14,
    businessName: 'Lightsy Images LLC',
    productCategory: 'Gifts',
    companyWebsite: 'https://lightsyimages.com',
    companyDescription:
      'Lightsy Images is a Black Inspired Greeting Card Company that uses fine art to tell our everyday stories.',
    image:
      'https://api.typeform.com/responses/files/f22e25e62eee665fa96426c61404f6409234624ac9b06fc9cfeb97d669422db2/OBWS.jpg',
  },

  {
    id: 15,
    businessName: 'Makeba',
    productCategory: 'Beauty',
    companyWebsite: 'https://www.lovemakeba.com',
    companyDescription:
      'Makeba is a complete line of hair, skincare, and lifestyle products using natural and Ayurvedic ingredients that promote holistic healing and results for the naturally beautiful.',
    image:
      'https://api.typeform.com/responses/files/7ae45f17ac077e0c16bca03221d7c3b8a20f9bd8501fd4265a7d9ac5c2142b94/20200901_165108_1_.png',
  },

  {
    id: 16,
    businessName: "Maya's Cookies",
    productCategory: 'Food & Beverage',
    companyWebsite: 'https://www.MayasCookies.com',
    companyDescription:
      "Founded in 2015 by Maya Madsen, Maya's Cookies is America's #1 Black-Owned Gourmet Vegan Cookie Company. As a vegan with a sweet tooth, she had not been able to find that perfectly delicious, soft-baked gourmet vegan cookie, so she spent years crafting her award-winning and crowd-pleasing recipes. From the classic to unique and forward-thinking flavors such as \"Chocolate Chip S'mores\" and \"Marble Fudge\", every flavor has a story grounded in Maya's travels, memories, and experiences. Alongside providing the highest quality product that appeals to everyone, Maya's Cookies is committed to superior customer service and community engagement, with a focus on youth and underserved communities. Maya's Cookies has a storefront in San Diego and also ships nationwide- simply order as a treat for yourself or as a gift.",
    image:
      'https://api.typeform.com/responses/files/503a8f1efc5aa4a49bf660a0646881de6755f458496b6a14d3c77dc399c3bfc3/2E7A8812_Edit.jpg',
  },

  {
    id: 17,
    businessName: 'My Filibo',
    productCategory: 'Accesories',
    companyWebsite: 'https://www.myfilibo.com',
    companyDescription:
      'My Filibo is a fashion store that produces lapel pins, apparel, stationery and other accessories designed with deep-rooted meanings. At My Filibo, we believe that no matter your tribe or the color of your skin, you should be proud of your origin, and show it off in your everyday dressing in a simple and elegant way. We also believe that one can set the mood for the day, make a fashion statement and showcase one’s identity by just wearing simple and trendy lapel pins and other smartly designed accessories.  By making those products we seek to send a positive message of hope and empowerment, bring optimism and uplift spirit.',
    image:
      'https://api.typeform.com/responses/files/47ff88a9f36ff4dcfb52f8ba4a8959b8dd6ff0b88c026309f729dc3ba9e8a67d/IMG_5313.PNG',
  },

  {
    id: 18,
    businessName: 'Palette Pots',
    productCategory: 'Homegoods',
    companyWebsite: 'http://palettepots.com',
    companyDescription:
      'Palette Pots is a style hub that provides vibrant colored and stylish planter pots for house plants. Palette Pots comes in a variety of colors and sizes for your plant baby. Palette Pots is bringing style to the world of plants!',
    image:
      'https://api.typeform.com/responses/files/544b835cb149a295e0d63c76d5b927515e135c8ab25173c6f9eacf333de05912/IMG_6676.jpg',
  },

  {
    id: 19,
    businessName: 'RAW EDGE BODY CARE',
    productCategory: 'Skincare',
    companyWebsite: 'https://www.rawedgebodycare.com/',
    companyDescription:
      'We are beauty company based in Los Angeles lead by black chemists.  We sell high performance products expertly formulated for dry skin using pure, raw and natural ingredients. We have launched with a CBD infused Body Butter formulated especially for black skin needs.  We use only healthy, nutritious, sustainably sourced ingredients that are great for your skin and no questionable ingredients!',
    image:
      'https://api.typeform.com/responses/files/7986f8e3d7c7031f1325099c5e2b6090fc5a8ce6faf0adc3185248ee6219eb37/TheFactoryDigital_RawEdge__7.jpg',
  },

  {
    id: 20,
    businessName: 'Tea and I',
    productCategory: 'Food & Beverage',
    companyWebsite: 'https://www.teaandi.com',
    companyDescription:
      'Tea and I is a functional tea brand for tea lovers who want to feel their absolute best. Our 100% organic full leaf teas are handcrafted using only premium healing herbs, spices, and love. Whether you want to feel healthier, be more energetic, or boost your immune system, we have a tea to fit your lifestyle. We believe that tea makes for the best company. Add a cup of wellness to your daily routine so that you can be—and look like—a healthier, happier you.',
    image:
      'https://api.typeform.com/responses/files/fde120eae3293022dc283da1c3698d0473c23cbd9beedc4395c61ee3ecae203a/Tea_and_I_Family_of_Products.png',
  },

  {
    id: 21,
    businessName: 'THEBRAIDRELEASER',
    productCategory: 'Hair Care',
    companyWebsite: 'https://www.thebraidsreleaser.com',
    companyDescription:
      'The tool provides a pain free, time efficient experience while taking down braids.',
    image: require('../assets/img/businesses/braid.jpg').default,
  },

  {
    id: 22,
    businessName: "Zella Mae's",
    productCategory: 'Hair Care',
    companyWebsite: 'https://zellamaesonline.com',
    companyDescription: 'We sell natural body and hair care products.',
    image:
      'https://api.typeform.com/responses/files/7139cc4d1f4b443070a66d5596beebb5e4f6ab4856ab74c5bc5842ad9246f4a2/IMG_2172.jpg',
  },
];

export default businessData;

window.data = businessData;
