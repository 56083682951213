export const defaultTheme = {
  theme: 'light',
  colors: {
    primary: '#ff6e26',
  },

  sizes: {
    h1: {
      sm: '1.8rem',
      md: '2.5rem',
      lg: '3rem',
      xl: '3.5rem',
    },

    h2: {
      sm: '1.2rem',
      md: '1.5rem',
      lg: '2rem',
      xl: '2.5rem',
    },
  },
};
