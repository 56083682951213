import styled from 'styled-components';

import main_bg from '../../assets/img/main-bg.png';

export const PageWrapper = styled.div`
  min-height: 100vh;
  padding-top: 4rem;
  background-image: url(${main_bg});
  background-color: #b8e2f7;
  background-size: cover;

  @media (min-width: 768px) {
    padding-top: 6rem;
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1600px) {
  }
`;
