import React from 'react';

import { StyledTopHeader } from './top-header.styled';

export default function TopHeader() {
  return (
    <StyledTopHeader>
      <h1>BHM 2021</h1>
      <div className="market-place-bg"></div>
      {/* <p>
        At OBWS we're on a mission to amplify the efforts of black-owned
        businesses as often as possible. Get to <br /> know our Black History
        Month vendors by joining us for daily take-overs via our <br />{' '}
        Instagram and Facebook pages.
      </p> */}

      <div className="sponsor-container">
        <h4>Presented by</h4>

        <a
          href="https://connect.clover.com/obws"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="logo"
            src={require('../../assets/img/clover.png').default}
            alt="supported by"
          />
        </a>
      </div>
    </StyledTopHeader>
  );
}
