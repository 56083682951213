import { useEffect } from 'react';

const useModalClass = (open) => {
  useEffect(() => {
    open && document.body.classList.add('open-modal');

    return () => {
      document.body.classList.remove('open-modal');
    };
  }, [open]);

  return {
    addModalClass: () => {
      document.body.classList.add('open-modal');
    },
    removeModalClass: () => {
      document.body.classList.remove('open-modal');
    },
  };
};

export default useModalClass;
