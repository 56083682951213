import styled from 'styled-components';

import market_bg from '../../assets/img/marketplace.png';

export const StyledTopHeader = styled.div`
  padding: 3rem 1.5rem;

  h1 {
    text-align: center;
  }

  .market-place-bg {
    height: 65px;
    width: 100%;
    background-image: url(${market_bg});
    background-size: 100% 100%;
  }

  p {
    text-align: center;
    font-size: 16px;

    br {
      display: none;
    }
  }

  .sponsor-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      margin-top: 2.5rem;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
    }

    .logo {
      width: 100px;
      height: 25px;
    }
  }

  @media (min-width: 768px) {
    padding: 4rem 9.5rem;

    .market-place-bg {
      height: 120px;
    }

    h1 {
      font-size: 4rem;
      font-weight: bold;
      letter-spacing: 0.64px;
      color: #141414;
    }

    p {
      font-size: 20px;
      br {
        display: inline-block;
      }
    }
  }

  @media (min-width: 1024px) {
    h1 {
      font-size: 5.8rem;
    }

    .market-place-bg {
      height: 136px;
    }

    p {
      font-size: 20px;
    }

    .sponsor-container {
      h4 {
        margin-top: 1rem;
        text-transform: uppercase;
        font-size: 18px;
      }

      .logo {
        width: 220px;
        height: 53px;
        object-fit: cover;
      }
    }
  }

  @media (min-width: 1600px) {
    h1 {
      font-size: 6rem;
    }

    p {
      font-size: 24px;
    }
    .sponsor-container {
      h4 {
        margin-top: 2.5rem;
      }
    }
  }
`;
