import styled from 'styled-components';

export const GridItemWrapper = styled.div`
  display: table-caption;
  position: relative;
  background: white;
  font-size: 12px;

  .more-details {
    display: none;
    transition: all 0.9s ease-in-out;
  }

  &:hover {
    .more-details {
      display: block;
    }
  }

  .image {
    background: url(${({ image }) => image});
    height: 240px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .texts {
    padding: 0 10px;
    font-weight: 600;

    .small {
      font-size: 9px;
      font-weight: 400;
      color: #141414;
      text-transform: uppercase;
    }
  }
`;

export const Grid = styled.div`
  margin: 0 auto;
  max-width: 74em;
  padding: 1em 1.5rem;

  .grid {
    /* Grid Fallback */
    display: flex;
    flex-wrap: wrap;

    /* Supports Grid */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: minmax(150px, auto);
    grid-gap: 1em;
  }

  .module {
    /* Demo-Specific Styles */
    background: #eaeaea;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    /* Flex Fallback */
    margin-left: 5px;
    margin-right: 5px;
    flex: 1 1 200px;
  }

  .top {
    display: flex;
  }

  .top .line {
    width: -webkit-fill-available;
    height: 1px;
    margin-top: 27px;
    margin: 0 10px;
    margin-top: 27px;
    background: black;
    border: 1px solid black;
  }

  .buttom {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    background-color: #ff6e26;
    color: #fff;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 3rem;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
  }

  .line img {
    width: 50px;
    margin: 10px 15px;

    @media (min-width: 768px) {
      width: 100px;
      margin: 10px 50px;
    }

    @media (min-width: 1024px) {
    }

    @media (min-width: 1600px) {
    }
  }

  .icons .line {
    width: min-content;
    display: flex;
    margin: auto;
    text-align: center;
  }

  .icons {
    text-align: -webkit-center;
  }

  .center {
    text-align: center;
  }

  /* If Grid is supported, remove the margin we set for the fallback */
  @supports (display: grid) {
    .module {
      margin: 0;
    }
  }

  @media (min-width: 768px) {
    padding: 1em 0;

    .grid {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      grid-auto-rows: minmax(150px, auto);
      grid-gap: 1em;
    }
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1600px) {
  }
`;

export const Hover = styled.div`
  position: absolute;
  top: 0;
  height: -webkit-fill-available;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  width: -webkit-fill-available;

  p {
    span {
      display: block;
      margin-top: 0.3rem;
      color: #ff6e26;
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer;
      font-weight: bold;
      font-size: 110%;
    }
  }

  a {
    background: #ff6e26;
    position: absolute;
    bottom: 10px;
    width: 94%;
    left: 3%;
    border: 0;
    color: white;
    text-align: center;
    padding: 0.7rem 0;
  }
`;

export const ReadMoreContent = styled.div`
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .modal-image {
      background: url(${({ image }) => image});
      height: 200px;
      width: 95%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 5px;
    }

    .description {
      width: 100%;

      h2 {
        text-transform: uppercase;
        line-height: 1.2;

        .category {
          text-transform: uppercase;
          color: #ff6e26;
          font-size: 11px;
          font-weight: normal;
        }
      }

      p {
        font-size: 12px;
      }

      a {
        background: #ff6e26;
        width: 100%;
        border: 0;
        color: white;
        text-align: center;
        padding: 0.7rem 0;
        display: block;
        border-radius: 5px;
      }
    }
  }

  .close-btn {
    margin-top: 2rem;
    text-align: center;
    font-size: 14px;
    opacity: 0.6;

    span {
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  @media (min-width: 768px) {
    .content {
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .modal-image {
        min-height: 350px;
        height: 100%;
        width: 45%;
      }

      .description {
        width: 50%;
        margin-left: 20px;

        p {
          font-size: 14px;
        }
      }
    }
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1600px) {
  }
`;
