import React, { useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';

import isEscapeKey from '../../utils/isEscapeKey';

import { StyledModal, ModalContainer, CloseIcon } from './modal.styled';
import useModalClass from '../../utils/hooks/useModalClass';

const Modal = ({
  open,
  close,
  closeOnEscapePress,
  closeIcon,
  children,
  className,
  style,
}) => {
  const { removeModalClass } = useModalClass(open);

  const closeModal = useCallback(() => {
    close();
    removeModalClass();
  }, [close, removeModalClass]);

  useEffect(() => {
    document.onkeydown = function (e) {
      if (closeOnEscapePress) if (isEscapeKey(e)) closeModal();
    };
  }, [closeModal, closeOnEscapePress]);

  if (open) {
    return ReactDOM.createPortal(
      <StyledModal onClick={closeModal} className="animated--fade-in">
        <ModalContainer
          onClick={(e) => e.stopPropagation()}
          className={`${className} animated--grow-in`}
          style={style}
        >
          {closeIcon && <CloseIcon onClick={closeModal} />}
          {children}
        </ModalContainer>
      </StyledModal>,
      document.querySelector('#modal')
    );
  }

  return null;
};

Modal.defaultProps = {
  closeOnEscapePress: true,
  closeIcon: true,
};

export default Modal;
