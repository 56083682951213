import React, {
  useReducer,
  createContext,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import dataReducer from './reducer';
import uiTypes from './types';

const initialState = {
  open_nav: false,
};

const UIContext = createContext();

function UIProvider(props) {
  const [state, dispatch] = useReducer(dataReducer, initialState);

  const toggleNav = useCallback(() => {
    dispatch({
      type: uiTypes.toggle_nav,
    });
  }, [dispatch]);

  const value = useMemo(() => {
    return {
      openNav: state.open_nav,
      toggleNav,
    };
  }, [state, toggleNav]);

  return <UIContext.Provider value={value} {...props} />;
}

const useUI = () => useContext(UIContext);

export { UIContext, useUI };

export default UIProvider;
