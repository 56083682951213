import React from 'react';
import { ThemeProvider } from 'styled-components';
import ReactGA from 'react-ga';

import Hoc from './utils/Hoc';
import { defaultTheme } from './theme';
import GlobalStyles from './components/global.styled';
import Layout from './components/layout/layout.comp';
import TopHeader from './components/top-header/top-header.comp';
import Grid from './components/grid/grid.comp';
import UIProvider from './contexts/ui';

ReactGA.initialize('UA-63840607-2');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Hoc>
      <UIProvider>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyles />
          <Layout>
            <TopHeader />
            <Grid />
          </Layout>
        </ThemeProvider>
      </UIProvider>
    </Hoc>
  );
}

export default App;
