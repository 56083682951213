const useIsEscapeKey = (e) => {
  let isEscape = false;

  e = e || window.event;
  if ('key' in e) {
    isEscape = e.key === 'Escape' || e.key === 'Esc';
  } else {
    isEscape = e.keyCode === 27;
  }

  return isEscape;
};

export default useIsEscapeKey;
