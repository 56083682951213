import uiTypes from './types';

const initialState = {
  open_nav: false,
};

export default function UIReducer(state = initialState, action) {
  switch (action.type) {
    case uiTypes.toggle_nav:
      return {
        ...state,
        open_nav: !state.open_nav,
      };

    default:
      return state;
  }
}
