import React, { useState } from 'react';
import businessData from '../../mock-ups/business-data.mockup';
import Modal from '../custom-modal/modal.comp';
import { GridItemWrapper, Grid, Hover, ReadMoreContent } from './grid.styled';

const HoverElement = ({
  companyDescription,
  companyWebsite,
  setOpen,
  ...rest
}) => {
  return (
    <Hover {...rest}>
      <p>
        {companyDescription.substr(0, 300)}
        {companyDescription.length > 300 && (
          <span onClick={() => setOpen(true)}>read more</span>
        )}
      </p>
      <a href={companyWebsite} target="_blank" rel="noreferrer">
        SHOP
      </a>
    </Hover>
  );
};

const GridItem = ({
  image,
  businessName,
  productCategory,
  companyDescription,
  companyWebsite,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <GridItemWrapper className="module" image={image}>
      <Modal
        open={open}
        close={() => setOpen(false)}
        closeIcon={false}
        className="readmore-modal"
      >
        <ReadMoreContent image={image}>
          <div className="content">
            <div className="modal-image"></div>

            <div className="description">
              <h2>
                <span className="category">{productCategory}</span> <br />
                {businessName}
              </h2>
              <p>{companyDescription}</p>

              <a href={companyWebsite} target="_blank" rel="noreferrer">
                SHOP
              </a>
            </div>
          </div>

          <div className="close-btn">
            <span onClick={() => setOpen(false)}>close</span>
          </div>
        </ReadMoreContent>
      </Modal>

      <HoverElement
        companyDescription={companyDescription}
        companyWebsite={companyWebsite}
        open={open}
        setOpen={setOpen}
        className="more-details"
      />

      <div className="image" />
      <div className="texts">
        <p className="small">{productCategory}</p>
        <p className="large">{businessName}</p>
      </div>
    </GridItemWrapper>
  );
};

export default function GridCard() {
  const [offset, setOffset] = useState(12);
  const [data] = useState(businessData);

  const loadMore = () => {
    if (!(offset > businessData.length + 24)) {
      setOffset((prev) => prev + 12);
    }
  };

  return (
    <Grid>
      <div className="top">
        <hr className="line" />
        <p>SHOPS</p>
        <hr className="line" />
      </div>
      <div className="grid">
        {data &&
          data
            .slice(0, offset)
            .map(
              ({
                id,
                businessName,
                companyDescription,
                image,
                productCategory,
                companyWebsite,
              }) => (
                <GridItem
                  key={id}
                  businessName={businessName}
                  companyDescription={companyDescription}
                  image={image}
                  productCategory={productCategory}
                  companyWebsite={companyWebsite}
                />
              )
            )}
      </div>
      <div style={{ textAlign: 'center' }}>
        {offset <= businessData.length && (
          <button className="buttom" onClick={loadMore}>
            View More
          </button>
        )}

        <p className="center">SUPPORTED BY</p>
      </div>
      <div className="icons">
        <div className="line">
          <a href="http://wish.com/" target="_blank" rel="noreferrer">
            <img
              src={require('../../assets/img/wish.png').default}
              alt="supported by"
            />
          </a>
        </div>
      </div>
    </Grid>
  );
}
